<template lang="html">
    <li>
        <small v-show="index > 0 && item" class="inline-block text-center"><DotsHorizontalIcon class="w-5 h-5 mt-2 text-tb-gray"/></small>
        <p v-if="item" class="font-serif italic text-lg" >
            <span v-html="item.description"></span> <span v-show="!is_bundle_menu && item.price" class=""> - <span v-show="!item.hide_currency_symbol">$ </span>{{ item.price }}</span>
        </p>
    </li>
</template>


<script>
import {
    DotsHorizontalIcon
} from '@heroicons/vue/outline'

export default {
    props: {
        item: Object,
        is_bundle_menu: Boolean,
        index: Number,
    },
    components: {
        DotsHorizontalIcon,
    },
    data() {
        return {

        }
    },
    setup() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style lang="css" scoped>
</style>
