<template lang="html">
  <div id="menu-container">
      <div class="border-b border-zinc-400 pb-2 mb-5">
          <h3 class="m-5 mb-0 font-serif uppercase font-bold">{{ currentPage.name }}</h3>
          <small v-if="currentPage.is_bundle" class="normal-case inline-block text-center">({{ currentPage.bundle_price }})</small>
      </div>
      <div v-if="currentPage.isCover">
          <p class="font-serif text-xs  uppercase py-3" v-for="menu in currentPage.menus"  :key="menu.id">
              <a class=" " href="javascript:void(0);" @click="$emit('change-menu-page', menu.id, menu.index)">
                  <span v-html="menu.name"></span>
              </a>
          </p>
      </div>
      <ul v-else >
          <MenuItem  v-for="(item, index) in currentPage.courses" :key="index" :index="index" :item="item" :is_bundle_menu="currentPage.is_bundle" ></MenuItem>
      </ul>
  </div>
</template>

<script>
import MenuItem from './MenuItem.vue'

export default {
    props: {
        currentPage: Object,
    },
    components: {
        MenuItem,
    },
    emits: ['change-menu-page'],
    data() {
        return {}
    },
    setup() {
        return {}
    },
    created() {}
}
</script>

<style lang="css" scoped>
</style>
