<template>
<div class="min-h-full">
    <header class="bg-tb-gray shadow">
        <div class="text-center max-w-7xl mx-auto py-0 px-4 sm:px-6 lg:px-8">
            <img @click="changePage(0)" class="inline-block max-h-16" :src="require('@/assets/images/Logo_Trattoria_Benati_bg_black.jpg')" alt="" />
        </div>
    </header>
    <main>

        <nav>
            <DialogMenu :navigation="menus" @change-menu-page="changePage"></DialogMenu>
        </nav>
        <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 ">

            <!-- Replace with content -->
            <div class="px-4 py-6 sm:px-0">
                <div class="h-full px-2  shadow-md rounded-lg h-96 bg-white pt-2 pb-8">

                    <MenuPage v-if="!loading" :currentPage="currentPage" @change-menu-page="changePage" />
                    <Preloader v-if="loading" color="#3498db" :scale="0.6" />
                </div>
            </div>
            <!-- /End replace -->
        </div>
    </main>
</div>
</template>

<script>
// import TopMenu from './TopMenu.vue'
import DialogMenu from './DialogMenu.vue'
// import DropdownMenu from './DropdownMenu.vue'
import MenuPage from './MenuPage.vue'

import Preloader from './Preloader.vue'

const baseEndpoint = 'https://www.trattoriabenati.com.au/menus-tree.php'
// const baseEndpoint = 'http://127.0.0.1:8080/?rest_route=/wp/v2/'


export default {
    components: {
        Preloader,
        DialogMenu,
        MenuPage,
    },
    // emits: ['change-menu-page'],
    data() {
        return {
            loading: false,
            menus: [],
            currentPage: {},

        }
    },
    setup() {
        return {
            baseEndpoint,
        }
    },
    methods: {
        changePage(menuId, menuIndex) {
            this.setPage(menuId, menuIndex)
        },
        async setPage(menuId, menuIndex) {
            if (menuId === 0) {
                let mainPage = {
                    'index': -1,
                    'id': 0,
                    'name': 'Menus',
                    'current': true,
                    'isCover': true,
                    'menus': this.menus,
                    'is_bundle': false,
                    'bundle_price': '',
                }
                this.currentPage = mainPage
            } else {
                this.loading = true
                //await this.populateCourses(menuId, menuIndex)
                this.currentPage = this.menus[menuIndex]
                this.loading = false

            }
        },
        fetchData: async function() {
            this.loading = true
            // const _this = this
            let retrivedMenus = await this.axios.get(baseEndpoint)
            console.log(retrivedMenus.data)
            let allMenus = retrivedMenus.data
            await this.populateMenus(allMenus)
            this.changePage(0, -1)
            this.loading = false
        },
        populateMenus(allMenus) {
            allMenus.forEach((menu, index) => {
                let currentMenu = {
                    'index': index,
                    'id': menu.id,
                    'name': menu.title,
                    'description': menu.content,
                    'current': false,
                    'isCover': false,
                    'courses': menu.courses,
                    'is_bundle': menu.is_bundle,
                    'bundle_price': menu.bundle_price,
                }
                this.menus[index] = currentMenu
            })
        },
        // async populateCourses(menuId, menuIndex) {
        //     this.menus[menuIndex].courses = []
        //     await this.axios.get(baseEndpoint + 'menu/' + menuId).then(async (response) => {
        //         let coursesIds = response.data.meta.courses
        //         let suspended = 0
        //         coursesIds.forEach(async (courseId, index) => {
        //               this.axios.get(baseEndpoint + 'course/' + courseId).then(async (response) => {
        //                 if (response.data.status == 'publish') {
        //                     //https://vuejs.org/v2/guide/syntax.html#Raw-HTML
        //                     //https://vuejs.org/guide/essentials/template-syntax.html#raw-html
        //                     let currentCourse = {
        //                         'id': response.data.id,
        //                         'name': response.data.title.rendered,
        //                         'description': response.data.content.rendered,
        //                         'price': response.data.meta.course_price,
        //                         'hide_currency_symbol': response.data.meta.hide_currency_symbol
        //                     }
        //
        //                     this.menus[menuIndex].courses[index] = currentCourse
        //                 }
        //                 if (response.data.status == 'suspended') {
        //                     suspended++
        //                 }
        //                 if (this.menus[menuIndex].courses.length == (coursesIds.length - suspended)) {
        //                     this.loading = false
        //                 }
        //             }).catch((error) => {
        //                 console.log('Errore: ' + error.message)
        //                 return Promise.reject(error)
        //             })
        //         })
        //
        //     })
        // }
    },
    created() {
        this.fetchData()
    }
}
</script>


<style lang="css" scoped>

</style>
