<template lang="html">

    <div class="fixed bottom-0  w-full font-serif  shadow flex items-center justify-center">
        <button
          type="button"
          @click="openModal"
          class="px-4 py-2 text-sm w-full font-medium text-white bg-black  text-gray-800 bg-yellow-400 rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 uppercase font-bold"
        >
          Menù
        </button>
      </div>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class=" font-serif">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            leave="duration-200 ease-in"
          >
            <DialogOverlay class="fixed inset-0 opacity-75 bg-tb-gray" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
            >

                <a href="javascript:void(0);" @click="closeModal" class="inline float-right">
                    <XIcon
                        class="w-5 h-5 ml-2 text-orange-300 transition duration-150 ease-in-out group-hover:text-opacity-80"
                        aria-hidden="true"
                    />
                </a>
              <DialogTitle
                as="h3"
                class="text-center font-normal text-lg font-medium leading-6 text-gray-900 border-b border-zinc-400 pb-2 mb-1 pl-6 uppercase font-bold"
              >
                Menù
              </DialogTitle>

              <DialogDescription>

              </DialogDescription>
              <div class="text-xs uppercase">
                <ul class="">
                    <li  v-for="item in navigation" :key="item.id" :item="item" class="py-3 text-center" >
                        <a href="javascript:void(0);" @click="$emit('change-menu-page', item.id,item.index);closeModal()">{{ item.name }}</a>
                    </li>
                    <li class="pt-3 text-center normal-case text-gray-600 border-t border-zinc-400 pt-2 mt-2" >
                        <a href="javascript:void(0);" @click="$emit('change-menu-page', 0,0);closeModal()">Menus</a>
                    </li>
                </ul>

              </div>

              <div class="mt-0">
                <!-- <button
                  type="button"
                  class="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  @click="closeModal"
                >
                 Close
                </button> -->
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
</TransitionRoot>
</template>

<script>
import { XIcon } from '@heroicons/vue/outline'
import { ref } from "vue";
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    DialogDescription,
} from "@headlessui/vue";

export default {
    props: {
        navigation: Array,
    },
    emits: ['change-menu-page'],
    components: {
        XIcon,
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogOverlay,
        DialogTitle,
        DialogDescription,
    },
    setup() {
        let isOpen = ref(false);
        return {
            isOpen,
            closeModal() {
                isOpen.value = false
              },
            openModal() {
                isOpen.value = true
            },
        }
    },
    methods: {

    }
}
</script>

<style lang="css" scoped>
</style>
